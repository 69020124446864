import React, { useMemo, useRef } from "react";
import Carousel from "react-multi-carousel";
import styles from "./CustomCarousel.module.scss";
import { getLookupTableForNextSlides } from "react-multi-carousel/lib/utils/dots";
import { getOriginalIndexLookupTableByClones } from "react-multi-carousel/lib/utils/clones";
import { useRouter } from "next/router";

type TResponsiveKey = {
  breakpoint: { max: number; min: number };
  items: number;
  slidesToSlide: number;
  partialVisibilityGutter?: number;
};

type TResponsive = {
  [key: string]: TResponsiveKey;
};

type TProps = {
  responsive: TResponsive;
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  children: any;
  infinite?: boolean;
  partialVisible?: boolean;
  swipeable?: boolean;
  draggable?: boolean;
  arrows?: boolean;
  dots?: boolean;
  debug?: boolean;
  ssr?: boolean;
  minimumTouchDrag?: number;
  removeArrowOnDeviceType?: string[];
  removeDotsOnDeviceType?: string[];
  className?: string;
  autoPlay?: boolean;
};

const CustomDot = ({ onClick, getIsActive, index }) => {
  const [active, nextSlide] = getIsActive(index);
  return (
    <span
      className={active ? styles.CustomDotActive : styles.CustomDotInactive}
      onClick={() => onClick(nextSlide)}
    />
  );
};

const CustomLeftArrow = ({ onClick }) => {
  return (
    <img
      onClick={() => onClick()}
      className={styles.CustomLeftArrow}
      src={"/static/images/arrows/new-arrow.svg"}
      alt={""}
    />
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <img
      onClick={() => onClick()}
      className={styles.CustomRightArrow}
      src={"/static/images/arrows/new-arrow.svg"}
      alt={""}
    />
  );
};

const CustomButtonGroup = (props) => {
  const {
    next,
    previous,
    carouselState,
    goToSlide,
    childrenArr,
    infinite,
    responsive,
    arrows,
    dots,
    removeArrowOnDeviceType,
    removeDotsOnDeviceType,
    debug,
  } = props;
  const { slidesToShow, currentSlide, deviceType } = carouselState;

  const slidesToSlide = responsive[deviceType].slidesToSlide;

  let numberOfDotsToShow: number;
  if (!infinite) {
    numberOfDotsToShow =
      Math.ceil((childrenArr.length - slidesToShow) / slidesToSlide!) + 1;
  } else {
    numberOfDotsToShow = Math.ceil(childrenArr.length / slidesToSlide!);
  }

  // debug && console.log(childrenArr.length);
  // If u need to debug, use this prop and make things

  const nextSlidesTable = getLookupTableForNextSlides(
    numberOfDotsToShow,
    carouselState,
    {
      responsive: responsive,
      ssr: true,
      children: childrenArr,
    },
    childrenArr
  );

  const lookupTable = getOriginalIndexLookupTableByClones(
    slidesToShow,
    childrenArr
  );

  const currentSlides = lookupTable[currentSlide];

  const getIsActive = (index) => {
    if (!infinite) {
      const maximumNextSlide = childrenArr.length - slidesToShow;
      const possibleNextSlides = index * slidesToSlide!;
      const isAboutToOverSlide = possibleNextSlides > maximumNextSlide;
      const nextSlide = isAboutToOverSlide
        ? maximumNextSlide
        : possibleNextSlides;
      return [
        nextSlide === currentSlide ||
          (currentSlide > nextSlide &&
            currentSlide < nextSlide + slidesToSlide! &&
            currentSlide < childrenArr.length - slidesToShow),
        nextSlide,
      ];
    } else {
      const nextSlide = nextSlidesTable[index];
      const cloneIndex = lookupTable[nextSlide];
      return [
        currentSlides === cloneIndex ||
          (currentSlides >= cloneIndex &&
            currentSlides < cloneIndex + slidesToSlide!),
        nextSlide,
      ];
    }
  };

  const isArrowTurnedWithDeviceType =
    !removeArrowOnDeviceType.includes(deviceType);

  const isDotsTurnedWithDeviceType =
    !removeDotsOnDeviceType.includes(deviceType);

  if (numberOfDotsToShow <= 1) return null;

  const rightArrowVisible = useMemo(() => {
    if (infinite) return true;
    debug &&
      console.log(
        carouselState?.containerWidth,
        carouselState?.transform,
        Math.abs(carouselState?.containerWidth) -
          (Math.abs(carouselState?.transform) + 1)
      );
    return (
      Math.abs(carouselState?.containerWidth) -
        (Math.abs(carouselState?.transform) + 1) !==
      0
    );
  }, [carouselState, infinite, carouselState?.transform]);

  const leftArrowVisible = useMemo(() => {
    if (infinite) return true;
    debug &&
      console.log(
        carouselState?.containerWidth,
        carouselState?.transform,
        Math.abs(carouselState?.containerWidth) -
          (Math.abs(carouselState?.transform) + 1)
      );
    return (
      Math.abs(carouselState?.containerWidth) -
        (Math.abs(carouselState?.transform) + 1) ===
      0
    );
  }, [carouselState, infinite, carouselState?.transform]);

  return (
    <>
      {arrows && isArrowTurnedWithDeviceType && (
        <CustomLeftArrow onClick={() => previous()} key={"CustomLeftArrow"} />
      )}
      {/* <div className={styles.CustomButtonGroup}>
        <div className={styles.CustomButtonGroup_Container}>

          {dots &&
            isDotsTurnedWithDeviceType &&
            [...Array(numberOfDotsToShow)].map((item, i) => {
              return (
                <CustomDot
                  onClick={goToSlide}
                  getIsActive={getIsActive}
                  index={i}
                  key={`dot_${i}`}
                />
              );
            })}
        </div>
      </div> */}
      {arrows && isArrowTurnedWithDeviceType && (
        <CustomRightArrow onClick={() => next()} key={"CustomRightArrow"} />
      )}
    </>
  );
};

const CustomCarousel: React.FC<TProps> = ({
  responsive,
  containerClass,
  containerStyle,
  children,
  infinite = true,
  minimumTouchDrag = 70,
  partialVisible = false,
  swipeable = true,
  draggable = false,
  arrows = true,
  dots = true,
  removeArrowOnDeviceType = [""],
  removeDotsOnDeviceType = [""],
  debug = false,
  className = "",
  ssr = true,
  autoPlay = false,
}) => {
  const router = useRouter();

  const isInfinite = useMemo(() => {
    return children.length === 1 ? false : infinite;
  }, [children, infinite]);

  const CarouselRef = useRef<Carousel>(null);

  const negativeSlideFromLeft = useMemo(() => {
    const itemCount = CarouselRef?.current?.state?.totalItems;
    const deviceShowCount = CarouselRef?.current?.state?.slidesToShow;
    const device = CarouselRef?.current?.state?.deviceType;

    if (itemCount === deviceShowCount) {
      const distance =
        CarouselRef?.current?.props?.responsive[device]
          ?.partialVisibilityGutter * itemCount;

      if (distance > 0) {
        return -distance;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [
    CarouselRef,
    CarouselRef.current,
    router,
    // window,
    // window?.innerHeight,
    // window?.innerWidth,
  ]);

  return (
    <div
      style={containerStyle}
      className={`${containerClass} ${styles.CarouselContainer}`}
    >
      <Carousel
        autoPlay={autoPlay}
        responsive={responsive}
        ssr={ssr}
        ref={CarouselRef}
        swipeable={swipeable}
        draggable={draggable}
        minimumTouchDrag={minimumTouchDrag}
        infinite={isInfinite}
        partialVisible={partialVisible}
        renderDotsOutside={true}
        renderButtonGroupOutside={true}
        dotListClass={styles.CustomDotListClass}
        arrows={false}
        className={className}
        additionalTransfrom={negativeSlideFromLeft}
        customButtonGroup={
          <CustomButtonGroup
            childrenArr={children}
            infinite={infinite}
            responsive={responsive}
            arrows={arrows}
            dots={dots}
            removeArrowOnDeviceType={removeArrowOnDeviceType}
            removeDotsOnDeviceType={removeDotsOnDeviceType}
            debug={debug}
          />
        }
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
