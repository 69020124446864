import React, { Component } from "react";
import styles from "./blockContainer.module.scss";
import Link from "next/link";
import { sendMetrics } from "../../../utils/misc";
import { Popup } from "semantic-ui-react";

export default class BlockContainer extends Component<any, any> {
  props: {
    children?: JSX.Element;
    title?: string;
    isLink?: boolean;
    linkText?: string;
    linkUrl?: string;
    isSSR?: boolean;
    isGrid?: boolean;
    isEvents?: boolean;
    isBonus?: boolean;
    isSocial?: boolean;
    isBanner?: boolean;
    withMetric?: string;
    marginTop?: number | string;
    marginBottom?: number | string;
    marginTopMobile?: number | string;
    isMobile?: boolean;
    onCLick?: () => void;
    disabled?: boolean;
    tooltipCondition?: boolean;
    tooltip?: string;
    titleClass?: any;
    page?: string | undefined;
  };

  getLink = (
    isSSR,
    linkUrl,
    linkText,
    withMetric,
    onCLick,
    disabled,
    tooltipCondition,
    tooltip
  ): JSX.Element => {
    if (onCLick) {
      const click = (e) => {
        e.preventDefault();
        withMetric && sendMetrics(withMetric);
        onCLick();
      };
      if (tooltipCondition && tooltip)
        return (
          <Popup
            inverted
            basic
            style={{ backgroundColor: "#333333" }}
            position={"top center"}
            trigger={
              <div
                className={styles.BlockContainerLink}
                data-disabled={disabled ? "true" : "false"}
                onClick={click}
              >
                {linkText}
              </div>
            }
          >
            {tooltip}
          </Popup>
        );
      return (
        <div
          className={styles.BlockContainerLink}
          data-disabled={disabled ? "true" : "false"}
          onClick={click}
        >
          {linkText}
        </div>
      );
    }
    if (isSSR) {
      return (
        <Link href={linkUrl}>
          <a
            className={styles.BlockContainerLink}
            onClick={withMetric ? () => sendMetrics(withMetric) : null}
          >
            {linkText}
          </a>
        </Link>
      );
    }
    if (!isSSR) {
      return (
        <a
          className={styles.BlockContainerLink}
          href={linkUrl}
          onClick={withMetric ? () => sendMetrics(withMetric) : null}
        >
          {linkText}
        </a>
      );
    }
  };

  render() {
    const {
      children,
      title,
      isLink = true,
      linkUrl = "",
      isSSR = true,
      linkText,
      isGrid = false,
      isSocial = false,
      isEvents = false,
      isBanner = false,
      isBonus = false,
      withMetric = "",
      marginTop = 0,
      marginBottom = 96,
      marginTopMobile = 0,
      isMobile = false,
      disabled = false,
      tooltipCondition = false,
      tooltip = "",
      onCLick,
      page = "",
    } = this.props;
    return (
      <div
        className={styles.Block}
        style={{
          marginTop: isMobile ? marginTopMobile : marginTop,
          marginBottom: marginBottom,
        }}
      >
        <div
          className={page !== "new-lk" ? "container" : ""}
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          {title && linkText && (
            <div className={styles.BlockContainer}>
              <h3
                className={
                  styles.BlockContainerTitle + " " + this.props.titleClass
                }
              >
                {title}
              </h3>
              {isLink &&
                this.getLink(
                  isSSR,
                  linkUrl,
                  linkText,
                  withMetric,
                  onCLick,
                  disabled,
                  tooltipCondition,
                  tooltip
                )}
            </div>
          )}
          {!isEvents && !isBonus && !isSocial && !isBanner && (
            <div
              className={
                isGrid
                  ? styles.BlockContainerChildNM
                  : page !== "new-lk"
                  ? styles.BlockContainerChild
                  : ""
              }
            >
              {children}
            </div>
          )}
          {isEvents && (
            <div className={styles.BlockContainerChildEvents}>{children}</div>
          )}
          {isBonus && (
            <div className={styles.BlockContainerChildBonus}>{children}</div>
          )}
          {isSocial && (
            <div className={styles.BlockContainerChildSocial}>{children}</div>
          )}
          {isBanner && (
            <div className={styles.BlockContainerBanner}>{children}</div>
          )}
        </div>
      </div>
    );
  }
}
